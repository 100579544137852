/*
 * @Author: Kleber Pinel Bernardo da Silva
 * @Date:   2019-01-25 15:34:06
 * @Last modified by:   kleberpinel
 * @Last modified time: 2019-01-31T12:09:45-08:00
 */

export default () => ({
  userInfo: null,
  hasError: false,
  errorMessage: '',
  navBarExpanded: false,
  showChangeProfilePictureModal: false,
  paymentCta: true,
  verifyEmailCta: false,
  shouldLoadV2Contacts: true,
  shouldSeeV2ContactsToggle: false,
  isUpdatingV2ContactsFlag: false,
  hasAtLeastOneEmailAccount: false,
  shouldLoadV2Events: true,
  shouldSeeV2EventsToggle: false,
  shouldLoadQualify: true,
  isUpdatingQualifyFlag: false,
  isUpdatingV2EventsFlag: false,
  onboardingFinished: true,
  smsUsageInfo: {},
  registrationInfo: {},
  registrationStarterInfo: {},
  registrationLowStandardInfo: {},
  hasErrorGetRegistrationInfo: false,
  ulToken: '',
  ulClientLicenses: [],
  createRelationshipsLicenseSuccess: false,
  migrationError: false,
  isMigrationComplete: false,
});
